import React from "react";

export const Options = React.createContext(null);

export default function OptionsContext(props) {
    const [fortunes, setFortunes] = React.useState(false)
    const [villagers, setVillagers] = React.useState(false)
    const [aeg, setAeg] = React.useState(false)
    const [treetop, setTreetop] = React.useState(false)

    const [singlePlayer, setSinglePlayer] = React.useState(false)


    const defaultOptions = {
        fortunes: {value: fortunes, set: setFortunes},
        villagers: {value: villagers, set: setVillagers},
        aeg: {value:aeg, set: setAeg},
        treetop: {value: treetop, set: setTreetop},

        singlePlayer: {value: singlePlayer, set: setSinglePlayer},
    }

    return (
        <Options.Provider value={defaultOptions}>
            {props.children}
        </Options.Provider>
    )
}
