import React, {useEffect, useState} from 'react'
import Table from "ts-jsx-table";
import TabGroup from 'ts-jsx-tabs';

import wellsJSON from '../cards/wells.json'
import factoriesJSON from '../cards/factories.json'
import churchJSON from '../cards/church.json'
import tavernJSON from '../cards/tavern.json'
import shopsJSON from '../cards/shops.json'
import barnJSON from '../cards/barn.json'
import wondersJSON from "../cards/wonders.json";
import villageSingleJSON from "../cards/villiage_single.json"
import VillageMultiJSON from "../cards/village_multi.json"

import wellsSVG from '../icons/tt-wells.svg'
import factoriesSVG from '../icons/tt-factory.svg'
import churchSVG from '../icons/tt-churchs.svg'
import tavernSVG from '../icons/tt-tavern.svg'
import shopsSVG from '../icons/tt-shops.svg'
import barnSVG from '../icons/tt-farms.svg'
import wonderSVG from '../icons/tt-wonder.svg'

const decideData = (file) => {
    switch (file) {
        case "village":
            return [...villageSingleJSON, ...VillageMultiJSON]
        case "wonder":
            return wondersJSON;
        case "barn":
            return barnJSON;
        case "shop":
            return shopsJSON;
        case "tavern":
            return tavernJSON;
        case "church":
            return churchJSON;
        case "factory":
            return factoriesJSON;
        case "well":
            return wellsJSON;
        default:
            return [{name: "", game: "", singlePlayer: true}]
    }
}

export default function DisplayAll(props) {

    const [tab, setTab] = useState("wonder")

    const [tableData, setTableData] = useState(wondersJSON)


    useEffect(() => {
        setTableData(decideData(tab))
    }, [tab])

    const columns = [
        {
            headerTitle: "Name",
            attribute: "name",
        },
        {
            headerTitle: "Game",
            attribute: "game",
        },
        {
            headerTitle: "Single Player",
            cellRender: tableRow => <>{tableRow.singlePlayer ? "" : "No"}</>
        },
    ]

    const tabFactory = ({Title, attribute, svg}) => ({
        Title: Title,
        RenderTitle: (<>{Title}&nbsp;<img src={svg} alt={""} style={{height: '1rem'}}/> </>),
        attribute: attribute,
        button: {style: {display: 'flex', alignItems: 'center'}}
    })

    const tabs = [
        ...[
            {Title: "wells", attribute: "well", svg: wellsSVG},
            {Title: "factories", attribute: "factory", svg: factoriesSVG},
            {Title: "churches", attribute: "church", svg: churchSVG},
            {Title: "taverns", attribute: "tavern", svg: tavernSVG},
            {Title: "shops", attribute: "shop", svg: shopsSVG},
            {Title: "barns", attribute: "barn", svg: barnSVG},
            {Title: "wonders", attribute: "wonder", svg: wonderSVG},
        ].map(tabFactory),
        {Title: "village", attribute: "village"},
    ]

    return (<div>

        {/*<div className="tab">*/}
        {/*    <button className={tabClass("well")} onClick={() => handleTab("well")}>Wells</button>*/}
        {/*    <button className={tabClass("factory")} onClick={() => handleTab("factory")}>Factories</button>*/}
        {/*    <button className={tabClass("church")} onClick={() => handleTab("church")}>Church</button>*/}
        {/*    <button className={tabClass("tavern")} onClick={() => handleTab("tavern")}>Tavern</button>*/}
        {/*    <button className={tabClass("shop")} onClick={() => handleTab("shop")}>Shop</button>*/}
        {/*    <button className={tabClass("barn")} onClick={() => handleTab("barn")}>Barn</button>*/}
        {/*    <button className={tabClass("wonder")} onClick={() => handleTab("wonder")}>Wonders</button>*/}
        {/*    <button className={tabClass("village")} onClick={() => handleTab("village")}>Villagers</button>*/}

        {/*</div>*/}

        <TabGroup
            tabs={tabs}
            currentTab={tab}
            setCurrentTab={setTab}
        />

        <Table
            tableColumns={columns}
            tableRows={tableData}
        />


    </div>)
}