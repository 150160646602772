import React from "react";

export default function VillagerCards({cards}) {
    return (<ul className="villager-cards">
            {cards.map((c, i) => <VillagerCard key={c.name + ":" + i} card={c}/>)}
        </ul>
    )
}

function VillagerCard({card}) {
    return (
        <li className="villager-card" key={card.name}>
            {card.name}
        </li>
    )
}
