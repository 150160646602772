import React, {useContext} from 'react';
import OptionsGroup from "./OptionsGroup";
import {Options} from "../OptionsContext";
import {nullCheck, pickCard, pickVillagerCard} from "../logic/cardSort";
import Cards from "./Cards";
import VillagerCards from "./VillagerCards";
import './Randomizer.css'

import wellsJSON from '../cards/wells.json'
import factoriesJSON from '../cards/factories.json'
import churchJSON from '../cards/church.json'
import tavernJSON from '../cards/tavern.json'
import shopsJSON from '../cards/shops.json'
import barnJSON from '../cards/barn.json'

import villageSingleJSON from "../cards/villiage_single.json"
import villageMultiJSON from "../cards/village_multi.json"

export default function Randomizer(props) {
    let {fortunes, villagers, aeg, treetop, singlePlayer} = useContext(Options)

    const [cards, setCards] = React.useState([])
    const [villageCards, setVillageCards] = React.useState([])

    const handleDraw = () => {
        setCards(
            [
                {"name": "cottage", "game": "base"},
                ...[
                    wellsJSON, factoriesJSON, churchJSON, tavernJSON, shopsJSON, barnJSON
                ].map(
                    pickCard({fortunes, villagers,aeg, treetop, singlePlayer})
                ).filter(nullCheck)
            ]
        )
        if (villagers.value) {
            setVillageCards([
                villageSingleJSON,
                villageMultiJSON
            ].map(
                pickVillagerCard({fortunes, villagers, singlePlayer})
            ))
        } else {
            setVillageCards([])
        }
    }

    return (<div>
        Buildings:
        <Cards cards={cards}/>
        <br/>
        {villagers.value && <>
            Villager Cards:
            <VillagerCards cards={villageCards}/>
            <br/></>}
        <button onClick={handleDraw}>draw</button>
        <br/>
        <OptionsGroup/>
    </div>)
}
