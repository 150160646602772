import React, {useContext} from 'react'
import OptionsGroup from "./OptionsGroup";
import {pickCard} from "../logic/cardSort";
import Cards from "./Cards";
import {Options} from "../OptionsContext";
import './Wonders.css'

import wondersJSON from "../cards/wonders.json";

export default function Wonders(props) {
    let {fortunes, villagers, singlePlayer, aeg, treetop} = useContext(Options)

    const [cards, setCards] = React.useState([])

    const handleDraw = () => {
        const temp = [];

        while (temp.length < 2) {
            let newCard = pickCard({fortunes, villagers, aeg, treetop, singlePlayer})(wondersJSON)

            if (temp.indexOf(newCard) === -1) {
                temp.push(newCard)
            }
        }
        setCards(temp)
    }

    // with expansions
    // have max of 4 from each
    //

    return (<div>
        <Cards cards={cards} wonders/>
        <br/>

        <button onClick={handleDraw}>draw</button>
        <br/>

        <OptionsGroup/>
    </div>)
}