import React, {useState} from 'react';
import './App.css';
import Randomizer from "./Randomizer";
import DisplayAll from "./DisplayAll";
import Wonders from "./Wonders";
import TabGroup from 'ts-jsx-tabs';
import hammerSVG from '../icons/hammer.svg';
import wonderSVG from '../icons/tt-wonder.svg'

function App() {

    const [tab, setTab] = useState("randomizer")

    const shadow = (thickness, color) => ({textShadow: `-${thickness}px ${thickness}px ${thickness}px ${color}, ${thickness}px ${thickness}px ${thickness}px ${color}, ${thickness}px -${thickness}px ${thickness}px ${color}, -${thickness}px -${thickness}px ${thickness}px ${color}`})

    const tabs = [
        {
            Title: "Randomizer",
            RenderTitle: (<>Randomizer&nbsp;<img src={hammerSVG} alt={""} style={{height: '1rem'}}/> </>),
            attribute: "randomizer",
            button: {style: {display: 'flex', alignItems: 'center'}}
        },
        {
            Title: "Wonders",
            RenderTitle: (<>Wonders&nbsp;<img src={wonderSVG} alt={""} style={{height: '1rem'}}/> </>),
            attribute: "wonders",
            button: {style: {display: 'flex', alignItems: 'center'}}
        },
        {Title: "All Cards", attribute: "table"},
    ]

    return (
        <div className="App">
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <h2 style={{color: 'lightgoldenrodyellow', ...shadow(3, 'saddlebrown')}}>Tiny Town Randomizer</h2>
                <img src={hammerSVG} alt={"icon"} style={{flexShrink: 3}}/>
            </div>
            <TabGroup
                tabs={tabs}
                currentTab={tab}
                setCurrentTab={setTab}
            />

            {tab === "randomizer" &&
            <Randomizer/>
            }
            {tab === "wonders" &&
            <Wonders/>
            }
            {tab === "table" &&
            <DisplayAll/>
            }
        </div>
    );
}

export default App;
