const filterCardsByExpansion = (fortunes, villagers, aeg, treetop) => (card) => (
    (card.game === "base")
    || (fortunes.value && card.game === "fortunes")
    || (villagers.value && card.game === "villagers")
    || (aeg.value && card.game === "aeg")
    || (treetop.value && card.game === "trees")
)

const filterVillagerCardsByExpansion = (fortunes, villagers) => c => (
    (fortunes.value && c.game === "fortunes")
    || c.game === "villagers"
);

const singlePlayerCheck = (singlePlayer) => (card) => (
    singlePlayer.value ? (card.singlePlayer ? card : null) : card
)

export const pickCard = (context) => (list) => {
    let filteredList = list
        .filter(singlePlayerCheck(context.singlePlayer))
        .filter(nullCheck)
        .filter(filterCardsByExpansion(context.fortunes, context.villagers, context.aeg, context.treetop))

    if (filteredList.length) {
        let card = filteredList[Math.floor(Math.random() * filteredList.length)];
        return card;
    }
    return null
}

export const pickVillagerCard = (context) => (list) => {
    let filteredList = list
        .filter(filterVillagerCardsByExpansion(context.fortunes, context.villagers))
    if (filteredList.length) {
        let card = filteredList[Math.floor(Math.random() * filteredList.length)];
        return card;
    }
    return null
}

export const nullCheck = c => c != null

